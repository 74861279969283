import React, { createContext, useEffect, useState } from "react";
import AdditionalSearchEnginePopUp from "../../component/PopUpWindow/AdditionalSearchEnginePopUp";
import SearchCardBox from "../../searchComponent/SearchCardBox";
import axios from "axios";
import Loading from "../../skeleton/Loading.js";
const SendData = createContext();
const SearchPage = () => {
  const [resource, SetResource] = useState([]);
  const [pageNo, SetPageNo] = useState(1);
  const [loading, SetLoading] = useState(true);
  useEffect(() => {
    try {
      axios
        .get(
          `https://jsonplaceholder.typicode.com/posts?_limit=9&_page=${pageNo}`
        )
        .then((res) => {
          SetResource((prev) => [...prev, ...res.data]);
          SetLoading(false);
        });
    } catch (error) {
      SetLoading(true);
    }
  }, [pageNo]);

  const handelInfiniteScroll = async () => {
    // console.log("sroolheight" + document.documentElement.scrollHeight);
    // console.log("sroolheight" + window.innerHeight);
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        SetLoading(true);
        SetPageNo((prev) => prev + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handelInfiniteScroll);
    return () => window.removeEventListener("scroll", handelInfiniteScroll);
  }, []);

  return (
    <>
      <AdditionalSearchEnginePopUp/>
      <SendData.Provider value={resource}>
        <SearchCardBox />
        {loading && <Loading />}
      </SendData.Provider>
    </>
  );
};

export default SearchPage;
export { SendData };
