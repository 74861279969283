import React from "react";
import "../../css/HomePage.css";
import "../../css/PopUpModel.css";
import "../../css/AdsCard.css";
import "../../css/LableBtn.css";
import "../../css/HomeVideo.css";
import "../../css/AddPropertySection.css";
import "../../css/BootstrapModelCenter.css";
import RentImage from "../../img/rent.png";
import BuyImage from "../../img/buy.png";
import HotelImage from "../../img/hotel.png";

import MainBackgroundImage from "../../img/mainBackground.jpg";

import ProductCarousel from "../../component/Carousel/ProductCarousel";
import BlogCarousel from "../../component/Carousel/BlogCarousel";
import "swiper/css";
import "swiper/css/pagination";

import { useNavigate } from "react-router-dom";
import AreaSelect from "../../component/PopUpWindow/AreaSelect";
import AddProperty from "../../component/AddProperty";

import HomeAdsContainersCarousel from "../../component/Carousel/HomeAdsContainersCarousel";
import HomeTrack from "../../component/HomeTrack";
import { useTranslation } from "react-i18next";
const HomeBuyPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <section
        className="homeMainEngine"
        style={{ backgroundImage: `url(${MainBackgroundImage})` }}
      >
        <section className="mainEngineSelectingBtn">
          <div className="selectBtnInnerItem" onClick={() => navigate("/")}>
            <div className="selectBtnInnerInnerItem">
              <img src={RentImage} alt="" />
              <h2>{t("rent")}</h2>
            </div>
          </div>
          <div
            className="selectBtnInnerItem"
            onClick={() => navigate("/buy")}
            style={{ color: "white", backgroundColor: "#fb8615" }}
          >
            <div className="selectBtnInnerInnerItem">
              <img src={BuyImage} alt="" />
              <h2>{t("buy")}</h2>
            </div>
          </div>
          <div
            className="selectBtnInnerItem"
            onClick={() => navigate("/hotel")}
          >
            <div className="selectBtnInnerInnerItem">
              <img src={HotelImage} alt="" />
              <h2>{t("hotel")}</h2>
            </div>
          </div>
        </section>
        <section className="mainEngineManageBox">
          <form action className="mainEngineManageBoxInner">
            <div
              className="mainEngineManageInnerSection"
              style={{ boxShadow: "0 0 3px black" }}
            >
              <input
                className="mainSearchForm"
                type="text"
                placeholder={t("location")}
              />
              <button
                className="mainSearchFormBtn"
                onClick={() => navigate("/search")}
              >
                <i className="fa-solid fa-map-location-dot" />
                <strong style={{ marginLeft: "5px" }}>{t("find")}</strong>
              </button>
            </div>
            <div className="mainEngineManageInnerSection">
              {/* area selcet component start */}
              <AreaSelect />
              {/* area selcet component end */}
              <select className="mainEngineAreaSelect" name id>
                <option value hidden>
                  {t("BuyCategory")}
                </option>
                <option value>{t("BuyCategoryFlat")}</option>
                <option value>{t("BuyCategoryLand")}</option>
              </select>
            </div>
            <div className="mainEngineManageInnerSection">
              <select
                style={{
                  width: "100%",
                  backgroundColor: "#fb8615",
                  color: "white",
                }}
                className="mainEngineAreaSelect"
                name
                id
              >
                <option value="none" hidden>
                {t("priceRange")}
                </option>
                <option value> {t("highPrice")}</option>
                <option value>{t("mediumPrice")}</option>
                <option value>{t("lowPrice")}</option>
              </select>
            </div>
          </form>
        </section>
      </section>

      {/* Home ContainersAds start */}
      <HomeAdsContainersCarousel />
      {/* Home ContainersAds end */}

      <section
        className="BestBuyContainer"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* Best Price start*/}
        <section className="labelTotal">
          <div className="lableTotalInner">
            <div className="lableTotalInnerLeft">
              <h2>Best Price</h2>
            </div>
            <div className="lableTotalInnerRight">
              <div className="lableTotalInnerRightCategoryBox">
              <button>{t("labelAll")}</button>
                <button>{t("labelHigh")}</button>
                <button>{t("labelMedium")}</button>
                <button>{t("labelLow")}</button>
              </div>
            </div>
          </div>
        </section>
        <ProductCarousel />
        {/* Best Price end*/}

        {/* Newest start */}

        <section className="labelTotal">
          <div className="lableTotalInner">
            <div className="lableTotalInnerLeft">
            <h2>{t("LatestNews")}</h2>
            </div>
            <div className="lableTotalInnerRight"></div>
          </div>
        </section>
        <ProductCarousel />
        {/* Newest end */}

        {/* Latest Blog  start*/}
        <section className="labelTotal">
          <div className="lableTotalInner newsLable">
            <div className="lableTotalInnerLeft">
              <h2>Letest News</h2>
            </div>
            <div className="lableTotalInnerRight newsBtnBox">
              <div className="lableTotalInnerRightCategoryBox newsBtnBoxHeight ">
              <button>{t("labelAll")}</button>
                <button>{t("labelHotel")}</button>
                <button>{t("labelLand")}</button>
                <button>{t("labelFlat")}</button>
                <button>{t("labelRent")}</button>
                <button>{t("labelBuy")}</button>
              </div>
            </div>
          </div>
        </section>
        <BlogCarousel />
      </section>
      {/* Latest Blog  end*/}

      {/* Home Truck Section start*/}
      <HomeTrack />
      {/* Home Truck Section end*/}

      <AddProperty />
    </>
  );
};

export default HomeBuyPage;
