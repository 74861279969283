import React from "react";
import "../../css/CardBox.css";
import PhoneNumberPopUpBox from "../PopUpWindow/PhoneNumberPopUpBox";
import MailPopUpBox from "../PopUpWindow/MailPopUpBox";
import { useNavigate } from "react-router-dom";

const CardBox = () => {
  const navigate = useNavigate();

  return (
    <>
      <section className="CardItemTotal">
        <div className="CardItemTotalInner">
          <img
            onClick={() => navigate("/productdetails")}
            style={{ cursor: "pointer" }}
            src="https://images.unsplash.com/photo-1600596542815-ffad4c1539a9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1175&q=80"
            alt=""
          />

          <div className="CardItemControlar">
            <div className="CardItemContolarInner">
              <div className="CardItemInner1">
                <div
                  className="CardItemInner1Left"
                  onClick={() => navigate("/productdetails")}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa-regular fa-calendar-days"> </i>
                  &nbsp; &nbsp;Jan 1 month ago
                </div>
                <div className="CardItemInner1Right">
                  <MailPopUpBox />

                  <PhoneNumberPopUpBox />
                </div>
              </div>

              <div
                className="CardItemInner2"
                onClick={() => navigate("/productdetails")}
                style={{ cursor: "pointer" }}
              >
                <div className="CardItemInner2Left">
                  <p>400000tk</p>
                </div>
                <div className="CardItemInner2Right">
                  <i className="fa-solid fa-bed"></i>&nbsp; 4 &nbsp;
                  <i className="fa-solid fa-bath"></i>&nbsp; 3 &nbsp;{" "}
                  <i className="fa-solid fa-chart-area"></i> &nbsp; 5000 sqrf
                </div>
              </div>

              <div
                className="CardItemInner3"
                onClick={() => navigate("/productdetails")}
                style={{ cursor: "pointer" }}
              >
                <i className="fa-solid fa-location-dot"></i>&nbsp; Bangla Motor
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CardBox;
