import React from "react";
import { useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const MailPopUpBox = () => {
  const [DilogOpen, SetOpenDilog] = useState(false);

  return (
    <>
      <button onClick={() => SetOpenDilog(true)} style={{ marginRight: "6px" }}>
      <i className="fa-solid fa-envelope"></i>
    
      </button>
      <Dialog open={DilogOpen} onClose={() => SetOpenDilog(false)}>
        <DialogTitle>This is Email</DialogTitle>
        <DialogContent>
          <DialogContentText>Show The Email Address</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => SetOpenDilog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MailPopUpBox;
