import React from "react";
import AddPropertyImg from "../img/AddProperty.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const AddProperty = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="addPropertySectionTotal">
        <div className="addPropertySectionTotalInner">
          <div className="addPropertySectionTotalInnerTop">
            <img src={AddPropertyImg} alt="" />
            <h3>
              {t("addPropertyBoxMessageFirstPart")}
              <br />
              {t("addPropertyBoxMessageSecondPart")}
            </h3>
          </div>
          <div className="addPropertySectionTotalInnerBottom">
            <Link to={"/addProperty"}>
              {" "}
              <i class="fa-solid fa-house-flag"></i>&nbsp; {t("addPropertyBtn")}
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddProperty;
