import React from "react";
import { useTranslation } from "react-i18next";

const HomeVideo = () => {
  const{t}=useTranslation();
  return (
    <>
      <section className="HomeVideoTotal">
        <div className="HomeVideoInner">
          <div className="HomeVideoInnerLeft">
            <div className="HomeVideoInnerLeftInner">
              <h2>
                Porosh<strong>Mart</strong>
              </h2>
              <h4>{t("HomeTrackSpeech")}</h4>
            </div>
          </div>
          <div className="HomeVideoInnerRight">
            <iframe
              width="70%"
              height="70%"
              src="https://www.youtube.com/embed/Cl3CcZAffTo"
              title="YouTube video player"
              frameBorder={0}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeVideo;
