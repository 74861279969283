import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../css/HeaderTop.css";
import BdLogo from "../img/bangladeshRoundeImg.png";
import UkLogo from "../img/ukLogo.png";
import MainLogo from "../img/mainLogo.png";
import i18next from "i18next";

const HeaderTop = () => {
  const [LangBtnName, SetLangBtnName] = useState();
  const [LangBtnNameReverse, SetLangBtnNameReverse] = useState();
  const [LangBtnLogo, SetLangBtnLogo] = useState(BdLogo);

  function ChangeLang() {
    if (LangBtnName === "BN") {
      localStorage.setItem("LangName", "EN");
      SetLangBtnName(localStorage.getItem("LangName"));
      SetLangBtnLogo(UkLogo);
      SetLangBtnNameReverse("BN");
    } else {
      localStorage.setItem("LangName", "BN");
      SetLangBtnName(localStorage.getItem("LangName"));
      SetLangBtnLogo(BdLogo);
      SetLangBtnNameReverse("EN");
    }
  }

  useEffect(() => {
    if (
      localStorage.getItem("LangName") === undefined ||
      localStorage.getItem("LangName") === null
    ) {
      localStorage.setItem("LangName", "BN");
      SetLangBtnName(localStorage.getItem("LangName"));

      i18next.changeLanguage(localStorage.getItem("LangName"));
    } else if (localStorage.getItem("LangName") === "BN") {
      SetLangBtnName(localStorage.getItem("LangName"));
      SetLangBtnNameReverse("EN");
      SetLangBtnLogo(BdLogo);
      i18next.changeLanguage(localStorage.getItem("LangName"));
    } else if (localStorage.getItem("LangName") === "EN") {
      SetLangBtnName(localStorage.getItem("LangName"));
      SetLangBtnNameReverse("BN");
      SetLangBtnLogo(UkLogo);
      i18next.changeLanguage(localStorage.getItem("LangName"));
    }
  }, [LangBtnName]);

  return (
    <>
      <section className="navbar">
        <div className="navbarInner">
          <button
            className="HambarggerBtn"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#staticBackdrop"
            aria-controls="staticBackdrop"
          >
            <i className="fa-solid fa-bars" />
          </button>
          <div
            className="offcanvas offcanvas-start"
            data-bs-backdrop="static"
            tabIndex={-1}
            id="staticBackdrop"
            aria-labelledby="staticBackdropLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="staticBackdropLabel">
                Offcanvas
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              />
            </div>
            <div className="offcanvas-body">
              <div>I will not close if you click outside of me.</div>
            </div>
          </div>
          <div className="navbarInnerLeft">
            <Link to="/">
              <img src={MainLogo} alt="" />
            </Link>
          </div>
          <div className="navbarInnerRight">
            <img src={LangBtnLogo} alt="" />
            <div className="dropdown">
              <button
                className="btn dropdown-toggle languageBtn"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {LangBtnName}
              </button>
              <div
                className="dropdown-menu languageSelectBtn"
                aria-labelledby="dropdownMenuButton"
              >
                <button className="dropdown-item" onClick={ChangeLang}>
                  {LangBtnNameReverse}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeaderTop;
