import HeaderTop from "./book/common/HeaderTop";
import HomeRentPage from "./book/pages/Home/HomeRentPage";
import HomeBuyPage from "./book/pages/Home/HomeBuyPage";
import HomeHotelPage from "./book/pages/Home/HomeHotelPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SearchPage from "./book/pages/Search/SearchPage";
import AddPropertyBtn from "./book/pages/AddPropertyPage/AddPropertyBtn";
import AddPropertyFlat from "./book/pages/AddPropertyPage/AddPropertyFlat";
import AddPropertyLand from "./book/pages/AddPropertyPage/AddPropertyLand";
import ProductSinglePage from "./book/pages/SinglePageDetails/ProductSinglePage";
import ScrollToTop from "./book/component/EngineFunction/ScrollToTop";
import BlogSinglePage from "./book/pages/SinglePageDetails/BlogSinglePage";
import LangEngine from "./book/locale/LangEngine";

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <HeaderTop />
        <Routes>
          <Route path="/" element={<HomeRentPage />}></Route>
          <Route path="/buy" element={<HomeBuyPage />}></Route>
          <Route path="/hotel" element={<HomeHotelPage />}></Route>
          <Route path="/search" element={<SearchPage />}></Route>
          <Route path="/addproperty" element={<AddPropertyBtn />}></Route>
          <Route path="/addproperty/flat" element={<AddPropertyFlat />}></Route>
          <Route path="/addproperty/land" element={<AddPropertyLand />}></Route>
          <Route path="/productdetails" element={<ProductSinglePage />}></Route>
          <Route path="/blogdetails" element={<BlogSinglePage />}></Route>
          <Route path="/t" element={<LangEngine />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
