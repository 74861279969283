import React, { useEffect, useState } from "react";
import SinglePageYoutubeDetailsVideo from "../../component/videoSections/SinglePageYoutubeDetailsVideo";
import "../../css/SinglePageDetails/ProductSinglePage.css";

import "../../css/SinglePageDetails/BlogSinglePage.css";
import ProductSinglePageCarousel from "../../component/Carousel/ProductSinglePageCarousel";
import SinglePageFooterNavbar from "../../component/SinglePageFooterNavbar";
import MainLogo from "../../img/mainlogo2.png";
import { useTranslation } from "react-i18next";

const ProductSinglePage = () => {
  const [ContentType, SetContentType] = useState();

  useEffect(() => {
    // flat/hotel/land
    SetContentType("hotel");
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <section className="SinglePageTotal">
        <section className="SinglePageTotalInner">
          <div className="SinglePageHeaderKey">
            <h2>PoroshMart - ID: 23237</h2>
          </div>
          <div className="ImageAndMessageContainner">
            <div className="LeftImageContainner">
              {/* <div className="LeftImageContainnerInner">
                <img src={MainBackgroundImage} alt="" />
              </div> */}
              <ProductSinglePageCarousel />
            </div>
            <div className="RightMessageContainner">
              <div className="RightMessageInner">
                <div className="RightMessageInnerLogo">
                  <img src={MainLogo} alt="" />
                </div>
                <div className="RightMainMessageBox">
                  <h3>{t("ProductSinglePageContact")}</h3>
                  <form className="RightMainMessageBoxForm" action>
                    <input
                      placeholder={t("addFlatFromEmailplaceHolder")}
                      className="RightMainMessageBoxFormItem"
                      type="email"
                      name
                      id
                    />
                    <input
                      placeholder={t("ProductSinglePhonePlaceHolder")}
                      className="RightMainMessageBoxFormItem"
                      type="email"
                      name
                      id
                    />
                    <textarea
                      placeholder={t("ProductSingleMessagePlaceHolder")}
                      className="RightMainMessageBoxFormItem RightMainMessageTextArea"
                      name
                      id
                      cols={30}
                      rows={10}
                      defaultValue={""}
                    />
                    <div className="RightMessageBtnBox">
                      <a
                        href
                        className="RightMessageBtn RightMessageBtnAncorTag"
                      >
                        <i className="fa-solid fa-phone-volume" /> &nbsp;{" "}
                        {t("ProductSingleCallBtn")}
                      </a>
                      <button className="RightMessageBtn">
                        <i className="fa-solid fa-envelope" />
                        &nbsp;
                        {t("ProductSingleMessagePlaceHolder")}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="SinglePageBodyText">
          <div className="SinglePageBodyTextInner">
            <div className="SinglePageBodyTextInnerComponent">
              <div className="SinglePageBodyTextInnerComponentLeft">
                <h2>BDT 42 THOUSANT</h2>
                <h3>Avenue 10, Mirpur DOHS, Mirpur, Dhaka</h3>
                <h3 style={{ marginTop: "10px" }}>For Rent: March 2023</h3>
                <div className="SinglePageBodyRightRoomTopInfo">
                  <div className="SinglePageBodyRightRoomTopInfoInnerItem">
                    <i className="fa-solid fa-bed" />
                    <p>4</p>
                  </div>
                  <div className="SinglePageBodyRightRoomTopInfoInnerItem">
                    <i className="fa-solid fa-bath" />
                    <p>4</p>
                  </div>
                  <div className="SinglePageBodyRightRoomTopInfoInnerItem">
                    <i className="fa-solid fa-warehouse" />
                    <p>10000 sqft</p>
                  </div>
                </div>
              </div>
              <div className="SinglePageBodyTextInnerComponentRight">
                <button className="SinglePageBodyTextInnerComponentRightBtn">
                  <i className="fa-regular fa-floppy-disk" /> Save
                </button>
                <a
                  href="#empty"
                  className="SinglePageBodyTextInnerComponentRightAncorTag"
                >
                  <i className="fa-brands fa-youtube" /> Youtube
                </a>
              </div>
            </div>
            <h3 className="SubTitleOfSingelPage">
              Offering you 2200 SFT Amazing Apartment for Rent in Mirpur DOSH
            </h3>
            <p className="SinglePageMainDescription">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Impedit
              esse aperiam, nulla quia velit temporibus iure eum similique
              inventore soluta quasi est reiciendis facere iste molestiae
              beatae, neque sed. Quisquam esse perferendis dolorem aut ducimus
              modi suscipit natus, harum dignissimos iure soluta nisi excepturi
              repudiandae maiores sequi cupiditate in! Mollitia hic molestiae
              aut laborum minus veritatis sint odit accusamus, nisi ut
              consequuntur maxime enim voluptatem commodi esse fuga voluptas
              doloremque officia voluptatum quisquam unde praesentium! Quasi
              doloremque hic, dolorum corporis quaerat esse? Architecto quisquam
              aliquid incidunt quod, quasi unde nobis iste maxime culpa laborum
              exercitationem perferendis mollitia ducimus, repellat aspernatur?
            </p>
          </div>
        </section>
        <section className="SinglePagetable">
          {/* Flat Teble Start */}

          {ContentType === "flat" ? (
            <>
              <div className="SinglePagetableTitle">
                <h2 style={{ marginBottom: "15px" }}>
                  {t("SinglePageFlatTableTitle")}
                </h2>
              </div>
              <table style={{ marginBottom: "50px" }}>
                <tbody>
                  <tr>
                    <th>{t("SinglePageTableItemName")}</th>
                    <th>{t("SinglePageTableItemQuantity")}</th>
                  </tr>
                  <tr>
                    <td>Property ID</td>
                    <td>2165645</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageTableItemType")}</td>
                    <td>Family flat</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageTableItemPerpose")}</td>
                    {/* <td>Sell Plot/ Share with Developer</td> */}
                    <td>TO RENT</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageTableItemAvailable")}</td>
                    <td>March 2023</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageTableItemPrice")}</td>
                    <td>4000TK </td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageTableItemTotalPrice")}</td>
                    <td>125151TK</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageTableItemBedroom")}</td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageTableItemBathroom")}</td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageTableItemKitchen")}</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageTableItemDrawingRoom")}</td>
                    <td>N/A</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageTableItemDiningRoom")}</td>
                    <td>N/A</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageTableItemParking")}</td>
                    <td>YES</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageTableItemApartment")}</td>
                    <td>3000(sqrt)</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageTableItemGash")}</td>
                    <td>Cylinder</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageTableItemFloarNo")}</td>
                    <td>5th</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageTableItemFloarType")}</td>
                    <td>Tiles</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageTableItemLift")}</td>
                    <td>NO</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageTableItemGenerator")}</td>
                    <td>YES</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageTableItemCCCamera")}</td>
                    <td>YES</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageTableItemElectricBill")}</td>
                    <td>Pre-Paid Meter</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageTableItemRoad")}</td>
                    <td>North 10 feet , South 10 feet</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageTableItemFlatPosition")}</td>
                    <td>
                      south facing {"{"}দক্ষিণ মুখী {"}"}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageTableItemFlatBuildTime")}</td>
                    <td>unknown</td>
                  </tr>
                </tbody>
              </table>
            </>
          ) : (
            <></>
          )}

          {/* Flat table End*/}

          {/* land Table Start */}

          {ContentType === "land" ? (
            <>
              <div className="SinglePagetableTitle">
                <h2 style={{ marginBottom: "15px" }}>
                  {t("SinglePageLandTableTitle")}
                </h2>
              </div>
              <table style={{ marginBottom: "50px" }}>
                <tbody>
                  <tr>
                    <th>{t("SinglePageTableItemName")}</th>
                    <th>{t("SinglePageTableItemQuantity")}</th>
                  </tr>
                  <tr>
                    <td>Property ID</td>
                    <td>2165645</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageTableItemType")}</td>
                    <td>Plots/General </td>
                  </tr>

                  <tr>
                    <td>{t("SinglePageTableItemAvailable")}</td>
                    <td>March 2023</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageTableItemPrice")}</td>
                    <td>4000TK </td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageTableItemTotalPrice")}</td>
                    <td>125151TK</td>
                  </tr>

                  <tr>
                    <td>{t("SinglePageTableItemLandSize")}</td>
                    <td>5415215</td>
                  </tr>

                  <tr>
                    <td>{t("SinglePageTableItemSizeUnit")}</td>
                    <td>Bigha/Katha/sotangso</td>
                  </tr>

                  <tr>
                    <td>{t("SinglePageTableItemRoad")}</td>
                    <td>North 10 feet , South 10 feet</td>
                  </tr>

                  <tr>
                    <td>{t("SinglePageTableItemLocation")}</td>
                    <td>unknown</td>
                  </tr>
                </tbody>
              </table>
            </>
          ) : (
            <></>
          )}

          {/* land Table End */}

          {/* Hotel Table Start */}

          {ContentType === "hotel" ? (
            <>
              <div className="SinglePagetableTitle">
                <h2 style={{ marginBottom: "15px" }}>
                  {t("SinglePageHotelTableTitle")}
                </h2>
              </div>
              <table style={{ marginBottom: "50px" }}>
                <tbody>
                  <tr>
                    <th>{t("SinglePageTableItemName")}</th>
                    <th>{t("SinglePageTableItemQuantity")}</th>
                  </tr>
                  <tr>
                    <td>Property ID</td>
                    <td>2165645</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageTableItemType")}</td>
                    <td>Hotel</td>
                  </tr>

                  <tr>
                    <td>{t("SinglePageTableItemTotalPrice")}</td>
                    <td>125151TK</td>
                  </tr>

                  <tr>
                    <td>{t("SinglePageHotelItemBed")}</td>
                    <td>3</td>
                  </tr>

                 
                  <tr>
                    <td>{t("SinglePageHotelItemRoomView")}</td>
                    <td> Hill View</td>
                  </tr>

                  <tr>
                    <td>{t("SinglePageHotelItemSize")}</td>
                    <td>3 Room</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageTableItemApartment")}</td>
                    <td>3000(sqrt)</td>
                  </tr>

                  <tr>
                    <td>{t("SinglePageTableItemLift")}</td>
                    <td>NO</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageTableItemGenerator")}</td>
                    <td>YES</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageTableItemCCCamera")}</td>
                    <td>YES</td>
                  </tr>

                  <tr>
                    <td>{t("SinglePageTableItemFlatPosition")}</td>
                    <td>
                      south facing {"{"}দক্ষিণ মুখী {"}"}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageTableItemRoad")}</td>
                    <td>North 10 feet , South 10 feet</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageHotelItemSmoking")}</td>
                    <td> N/A</td>
                  </tr>


                  <tr>
                    <td
                      colspan="2"
                      style={{
                        color: "white",
                        backgroundColor: "#fb8615",
                      }}
                    >
                      {t("SinglePageHotelItemFoodAndDring")}
                    </td>
                  </tr>

                  <tr>
                    <td>{t("SinglePageHotelItemBuffet")}</td>
                    <td> YES</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageHotelItemNormalResturnat")}</td>
                    <td> YES</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageHotelItemBrunchSetMenuLunch")}</td>
                    <td> YES</td>
                  </tr>

                  <tr>
                    <td
                      colspan="2"
                      style={{
                        color: "white",
                        backgroundColor: "#fb8615",
                      }}
                    >
                      {t("SinglePageHotelItemMeadiaAndTechnology")}
                    </td>
                  </tr>

                  <tr>
                    <td>{t("SinglePageHotelItemTelephone")}</td>
                    <td> YES</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageHotelItemPrinter")}</td>
                    <td> YES</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageHotelItemPhotocopier")}</td>
                    <td> YES</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageHotelItemComputer")}</td>
                    <td> YES</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageHotelItemMobilePhoneCoverage")}</td>
                    <td> YES</td>
                  </tr>
                  <tr>
                    <td> {t("SinglePageHotelItemTheater")} </td>
                    <td> YES</td>
                  </tr>

                  <tr>
                    <td
                      colspan="2"
                      style={{
                        color: "white",
                        backgroundColor: "#fb8615",
                      }}
                    >
                      {t("SinglePageHotelItemBusinessFacilites")}
                    </td>
                  </tr>

                  <tr>
                    <td> {t("SinglePageHotelItemAuditorium")} </td>
                    <td> YES</td>
                  </tr>

                  <tr>
                    <td
                      colspan="2"
                      style={{
                        color: "white",
                        backgroundColor: "#fb8615",
                      }}
                    >
                      {t("SinglePageHotelItemFutnessAndWellnessFacilities")}
                    </td>
                  </tr>

                  <tr>
                    <td>{t("SinglePageHotelItemMessage")}</td>
                    <td> YES</td>
                  </tr>
                  <tr>
                    <td> {t("SinglePageHotelItemHotHub")}</td>
                    <td> YES</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageHotelItemGym")}</td>
                    <td> YES</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageHotelItemHairDressingSalon")}</td>
                    <td> NO</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageHotelItemHeatedSwimmingPool")}</td>
                    <td> NO</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageHotelItemFacilites")}</td>
                    <td> NO</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageHotelItemSwimming Pool")}</td>
                    <td> YES</td>
                  </tr>
                  <tr>
                    <td>{t("SinglePageHotelItemBeautySalon")}</td>
                    <td> NO</td>
                  </tr>
                </tbody>
              </table>
            </>
          ) : (
            <></>
          )}

          {/* Hotel Table End */}

          <div className="SinglePagetableFooter">
            {/* This is only for hotel Start */}
            {ContentType === "hotel" ? (
              <>
                {" "}
                <h3>{t("SinglePageHotelGEneralFacility")}</h3>
                <p style={{ marginTop: "8px", marginBottom: "30px" }}>
                  Towel, Sofa Bed,Lockers, Garder, Ciffe/Tea in londdy, Currency
                  Exchange, Disability Friendly etc.
                </p>
              </>
            ) : (
              <></>
            )}

            {/* This is only for hotel End */}

            <h3>{t("SinglePageNearestAmenities")}</h3>
            <p style={{ marginTop: "8px", marginBottom: "30px" }}>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt
              consequuntur ipsum sequi est amet saepe expedita atque asperiores
              earum sit?
            </p>
            <div className="SinglePageFooterHighLightInfo">
              <h2>{t("SinglePageHighlightInformation")}</h2>
              <table style={{ marginTop: "20px", marginBottom: "30px" }}>
                <tbody>
                  <tr>
                    <th>{t("SinglePageTableItemPerpose")}</th>
                    <th>Rent</th>
                  </tr>
                  <tr>
                    <td>{t("SinglePageTableItemType")}</td>
                    <td>Family flat</td>
                  </tr>
                  <tr>
                    <th>{t("SinglePageRaferanceNo")}</th>
                    <th>1121212444444</th>
                  </tr>
                  <tr>
                    <td>{t("SinglePageTableItemAvailable")}</td>
                    <td>1 jun 2023</td>
                  </tr>
                  <tr>
                    <th>{t("SinglePageLastUpdate")}</th>
                    <th>1 jun 2023</th>
                  </tr>
                  <tr>
                    <td>{t("SinglePageTableItemTotalPrice")}</td>
                    <td>11000 TK</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="BistaritoBox">
              {/* <h3>বিস্তারিত জানতে কল করুন</h3> */}
              <h3>{t("SinglePageCallForDetails")}</h3>
              <button>
                <i className="fa-solid fa-phone-volume" />{" "}
                {t("ProductSingleCallBtn")}
              </button>
            </div>
          </div>
          <SinglePageYoutubeDetailsVideo />
        </section>
      </section>
      <SinglePageFooterNavbar />
    </>
  );
};

export default ProductSinglePage;
