import React, { useState } from "react";
import "../../css/HomePage.css";
import "../../css/PopUpModel.css";
import "../../css/AdsCard.css";
import "../../css/LableBtn.css";
import "../../css/HomeVideo.css";
import "../../css/AddPropertySection.css";
import "../../css/BootstrapModelCenter.css";
import "../../css/searchCss/AdditionalSearchEngine.css";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
} from "@mui/material";

const AdditionalSearchEngine = () => {
  const [DialogControl, SetDilogControl] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <section
        className="homeMainEngine"
        style={{
          boxShadow: "none",
          borderBottom: "1px rgb(99, 99, 99) solid",
          backgroundColor: "white",
          top: "69px",
          zIndex: "9",
          position: "sticky",
        }}
      >
        {/* <section className="mainEngineSelectingBtn" style={{marginTop:"30px"}}>
          <div className="selectBtnInnerItem" onClick={() => navigate("/")}>
            <div className="selectBtnInnerInnerItem">
              <img src={RentImage} alt="" />
              <h2>Rent</h2>
            </div>
          </div>
          <div className="selectBtnInnerItem" onClick={() => navigate("/buy")}>
            <div className="selectBtnInnerInnerItem">
              <img src={BuyImage} alt="" />
              <h2>Buy</h2>
            </div>
          </div>
          <div
            className="selectBtnInnerItem"
            onClick={() => navigate("/hotel")}
            style={{ color: "white", backgroundColor: "#fb8615" }}
          >
            <div className="selectBtnInnerInnerItem">
              <img src={HotelImage} alt="" />
              <h2>Hotel</h2>
            </div>
          </div>
        </section> */}
        <section
          className="mainEngineManageBox AdditionalSearchEngine"
          style={{ marginTop: "10px", marginBottom: "20px", height: "4rem" }}
        >
          <form
            action
            className="mainEngineManageBoxInner"
            style={{ flexDirection: "inherit" }}
          >
            <div
              className="mainEngineManageInnerSection"
              style={{ boxShadow: "0 0 3px black" }}
            >
              <input
                className="mainSearchForm"
                type="text"
                placeholder="  Location"
              />
              <button
                className="mainSearchFormBtn  additionalMainSearchFormBtn "
                onClick={() => navigate("/search")}
              >
                <i className="fa-solid fa-map-location-dot" />
                <strong style={{ marginLeft: "5px" }}>Find</strong>
              </button>
            </div>

            <div
              className="SearchFilterBtn"
              onClick={() => SetDilogControl(true)}
            >
              <span className="searchDilogInnerBtnTitle">Filter</span>
              <i class="fa-solid fa-arrow-down-wide-short"></i>
            </div>
            <Dialog open={DialogControl}>
              <DialogTitle>Dilog</DialogTitle>

              <DialogContent dividers>
                <DialogContentText>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Placeat quasi perspiciatis rem atque adipisci eaque.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" color="secondary">
                  Agree
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => SetDilogControl(false)}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        </section>
      </section>
    </>
  );
};

export default AdditionalSearchEngine;
