import React from "react";
import "../css/skeletonCss/loading.css"
const Loading = () => {
  return (
    <div className="main">
      <div className="c"></div>
      <div className="b"></div>
      <div className="a"></div>
    </div>
  );
};

export default Loading;
