import React from "react";
import { useTranslation } from "react-i18next";
import "../../css/SinglePageDetails/SinglePageYoutubeVideo.css"
const SinglePageYoutubeDetailsVideo = () => {
  const {t}=useTranslation();
  return (
    <>
      <section className="SinglePageYoutubeVideoSection">
        <div className="SinglePageYoutubeVideoSectionTitle">
          <h2>{t("SinglePageYoutubeVideoTitle")}</h2>
        </div>
        <div className="SinglePageYoutubeVideoSectionVideo">
          <iframe
            width="80%"
            height="100%"
            src="https://www.youtube.com/embed/Cl3CcZAffTo"
            title="YouTube video player"
            frameBorder={0}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </div>
      </section>
    </>
  );
};

export default SinglePageYoutubeDetailsVideo;
