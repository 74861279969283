import React, { useContext } from "react";
import { SendData } from "../pages/Search/SearchPage";
import "../css/searchCss/SearchCardBoxItem.css";
import { useNavigate } from "react-router-dom";
import PhoneNumberPopUpBox from "../component/PopUpWindow/PhoneNumberPopUpBox";
import MailPopUpBox from "../component/PopUpWindow/MailPopUpBox"
const SearchCardBoxItem = () => {
  const CallingData = useContext(SendData);
  const navigate = useNavigate();
  //   useEffect(() => {
  //     console.log(CallingData);
  //   });

  return (
    <>
      <section className="TotalBody">
        {CallingData.map((item, index) => (
          <section class="ProductCardItemTotal">
            <div class="CardItemTotalInner">
              <img
                onClick={() => navigate("/productdetails")}
                style={{ cursor: "pointer" }}
                src="https://images.pexels.com/photos/13525370/pexels-photo-13525370.jpeg?auto=compress&cs=tinysrgb&w=1600"
                alt=""
              />

              <div class="ProductCardItemControlar">
                <div class="ProductCardItemContolarInner">
                  <div class="ProductCardItemInner1">
                    <div
                      class="ProductCardItemInner1Left"
                      onClick={() => navigate("/productdetails")}
                      style={{ cursor: "pointer" }}
                    >
                      <i class="fa-regular fa-calendar-days"> </i>
                      &nbsp; &nbsp;Jan 1 month ago
                    </div>
                    <div class="CardItemInner1Right">
                      <MailPopUpBox />

                      <PhoneNumberPopUpBox />
                    </div>
                  </div>

                  <div
                    class="ProductCardItemInner2"
                    onClick={() => navigate("/productdetails")}
                    style={{ cursor: "pointer" }}
                  >
                    <div class="CardItemInner2Left">
                      <p>400000tk</p>
                    </div>
                    <div class="CardItemInner2Right">
                      <i class="fa-solid fa-bed"></i>&nbsp; 4 &nbsp;
                      <i class="fa-solid fa-bath"></i>&nbsp; 3 &nbsp;{" "}
                      <i class="fa-solid fa-chart-area"></i> &nbsp; 5000 sqrf
                    </div>
                  </div>

                  <div
                    class="ProductCardItemInner3"
                    onClick={() => navigate("/productdetails")}
                    style={{ cursor: "pointer" }}
                  >
                    <i class="fa-solid fa-location-dot"></i>&nbsp; Bangla Motor
                  </div>
                </div>
              </div>
            </div>
          </section>
        ))}
      </section>
    </>
  );
};

export default SearchCardBoxItem;
