import React from "react";
import HomeVideo from "../../component/HomeVideo";
import MainBackgroundImage from "../../img/mainBackground.jpg";
const BlogSinglePage = () => {
  return (
    <>
      <section className="BlogSinglePageSection">
        <div className="BlogSinglePageSectionImg">
          <img src={MainBackgroundImage} alt="" />
          <div className="BlogSinglePageSectionImgUpdateData">
            <p>
              <i className="fa-solid fa-pen-nib" /> 20 january 2023
            </p>
          </div>
        </div>
        <div className="BlogSinglePageSectionTitle">
          <h2>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Maxime,
            culpa assumenda! Quibusdam, adipisci. Soluta ad quam, ipsa magni qui
            nemo possimus, sequi ducimus at reiciendis in doloremque eaque error
            deserunt?
          </h2>
        </div>
        <div className="BlogSinglePageSectionBody">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eius ab quia
          ea mollitia ad, aperiam inventore cumque odit unde. Numquam suscipit
          quidem autem fugit omnis facere sapiente velit. Laborum quod possimus
          temporibus placeat tenetur incidunt mollitia, omnis, officia
          doloremque consequatur laboriosam expedita recusandae, veniam
          repellat? Exercitationem quasi illum perferendis modi vel illo
          architecto quod magnam laborum, mollitia eaque dolorem consequuntur
          ratione est quae ut in enim nihil. Assumenda, sed tenetur? Voluptate
          necessitatibus, aperiam laudantium doloribus culpa ratione facere
          voluptatum placeat magni itaque similique? Magni labore dolor alias,
          aliquid dolore pariatur est doloribus culpa repellat. Facilis, autem
          cumque. Consectetur totam quis recusandae nostrum quasi reprehenderit
          quae veritatis dolore saepe, ut, deleniti eaque veniam porro quaerat
          ab illo? Suscipit, omnis. Autem maiores numquam ab aut illum mollitia
          error qui in officiis, minus ipsa voluptatum magnam facilis id quia
          quos dignissimos unde optio sapiente. Suscipit harum dignissimos
          temporibus excepturi fuga dolorum sed aut commodi qui debitis
          repellendus possimus ab, modi magnam impedit quae nobis illo minus
          quos blanditiis doloribus explicabo corporis. Dolor sunt tenetur
          reiciendis. Reprehenderit ullam suscipit molestias exercitationem
          blanditiis libero atque dolorem neque cum nam, unde temporibus modi
          quis quo, ipsum provident nulla debitis sapiente quam ipsa quos
          corrupti repellat et. Debitis accusantium ex veritatis libero
          obcaecati quibusdam quasi officiis repellat eveniet, amet perferendis
          tempora repellendus dolorum vitae nobis odit molestiae cumque magni
          mollitia eaque facere consequuntur cum doloremque error! Blanditiis
          consectetur quasi et natus. Eius hic earum, blanditiis tempore
          voluptatum labore ea a est obcaecati non nemo, pariatur odit provident
          sunt ad at modi totam harum cum, assumenda dolorum vel commodi
          laudantium dignissimos! Accusantium, omnis soluta! Temporibus maiores
          iure quod ipsam natus dolorum architecto! Magnam doloremque, fugiat
          consectetur placeat facere cumque quis nulla incidunt aliquid illo
          perspiciatis nostrum eos. Distinctio, quae. Eius dicta a
          exercitationem quos obcaecati velit harum inventore necessitatibus
          animi! Tenetur doloribus laudantium veritatis, magnam veniam tempore
          at neque eaque repellat ut facilis illum! Ut odio dolores dolore,
          quasi, nihil distinctio voluptas ex molestias omnis at est eos hic
          asperiores exercitationem temporibus, porro ea explicabo aut
          voluptatem libero illo quidem. Libero qui quae voluptas quidem,
          aspernatur dignissimos aliquid. Natus, ipsum fugiat hic deleniti
          quibusdam ab repellat facilis soluta sint nesciunt aliquid harum totam
          fuga obcaecati doloremque possimus, veritatis voluptatem, nemo
          sapiente recusandae. Iure fuga neque repellendus hic voluptatum unde?
          Voluptates nihil iste distinctio aperiam maxime rerum eius laboriosam
          possimus eos, fugiat, sit maiores eaque! Ipsam accusamus aliquam
          exercitationem quae fugiat, voluptatem culpa debitis excepturi
          voluptate! Pariatur numquam alias ducimus temporibus at exercitationem
          repudiandae, iste similique natus rerum aliquam ipsum, beatae quisquam
          eveniet libero est, assumenda sed optio. Voluptas quo deleniti beatae
          nisi quaerat quod soluta quis ullam numquam pariatur. Hic adipisci
          sapiente, eius corporis deserunt ex quas tenetur impedit, saepe nisi
          autem inventore voluptatem, vitae blanditiis harum iusto? Iste
          asperiores similique laboriosam modi quaerat soluta non nam possimus.
          Earum eveniet necessitatibus similique aliquid, perspiciatis odio id
          consequuntur quibusdam hic sint, soluta architecto quod natus at
          culpa, labore ipsum laboriosam incidunt corrupti. Nemo sint similique
          molestias blanditiis, quaerat laborum.
        </div>
      </section>
     <HomeVideo/>
    </>
  );
};

export default BlogSinglePage;
