import React from "react";

const AreaSelect = () => {
  return (
    <section
      className="PopUPNull"
      style={{ width: "48%", height: "100%", borderRadius: "15px" }}
    >
      <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        style={{
          width: "100%",
          height: "100%",
          borderRadius: "15px",
          backgroundColor: "#fb8615",
          border: "none",
        }}
      >
        Area{" "}
        <i
          className="fa-solid fa-angle-down"
          style={{ float: "right", marginTop: "4px" }}
        />
      </button>
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                style={{ color: "#fb8615" }}
                className="modal-title"
                id="exampleModalLabel"
              >
                Select Area
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div
              className="modal-body"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h3 style={{ fontWeight: "bolder", color: "#fb8615" }}>
                Category
              </h3>
              <div className="ModeCategorSelect" style={{ fontWeight: "bold" }}>
                <label style={{ color: "#fb8615" }} htmlFor>
                  Division
                </label>
              </div>
              <select
                className="form-select p-2 m-3 ModeCategorSelect"
                aria-label="Default select example"
              >
                <option selected>Dhaka</option>
                <option value={1}>One</option>
                <option value={2}>Two</option>
                <option value={3}>Three</option>
                <option value={3}>Three</option>
                <option value={3}>Three</option>
                <option value={3}>Three</option>
                <option value={3}>Three</option>
                <option value={3}>Three</option>
                <option value={3}>Three</option>
                <option value={3}>Three</option>
                <option value={3}>Three</option>
                <option value={3}>Three</option>
                <option value={3}>Three</option>
                <option value={3}>Three</option>
                <option value={3}>Three</option>
                <option value={3}>Three</option>
                <option value={3}>Three</option>
                <option value={3}>Three</option>
                <option value={3}>Three</option>
                <option value={3}>Three</option>
                <option value={3}>Three</option>
                <option value={3}>Three</option>
                <option value={3}>Three</option>
                <option value={3}>Three</option>
                <option value={3}>Three</option>
                <option value={3}>Three</option>
                <option value={3}>Three</option>
                <option value={3}>Three</option>
                <option value={3}>Three</option>
              </select>
              <div className="ModeCategorSelect" style={{ fontWeight: "bold" }}>
                <label style={{ color: "#fb8615" }} htmlFor>
                  District
                </label>
              </div>
              <select
                className="form-select p-2 m-3 ModeCategorSelect"
                aria-label="Default select example"
              >
                <option selected>Dhaka</option>
                <option value={1}>One</option>
                <option value={2}>Two</option>
                <option value={3}>Three</option>
              </select>
              <div className="ModeCategorSelect" style={{ fontWeight: "bold" }}>
                <label style={{ color: "#fb8615" }} htmlFor>
                  Thana
                </label>
              </div>
              <select
                className="form-select p-2 m-3 ModeCategorSelect"
                aria-label="Default select example"
              >
                <option selected>Dhanmondi</option>
                <option value={1}>One</option>
                <option value={2}>Two</option>
                <option value={3}>Three</option>
              </select>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                style={{ backgroundColor: "black" }}
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                style={{ backgroundColor: "#fb8615", border: "none" }}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AreaSelect;
