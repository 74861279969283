import React from "react";
import { useTranslation } from "react-i18next";
import "../../css/AddPropertyPages/AddPropertyForm.css";

const AddPropertyFlat = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="appoinmentPage">
        <div className="appoinmentPageHeader">
          <h2>
            <i className="fa-solid fa-house-flag" />
            {t("addFlatFromTitle")}
          </h2>
        </div>
        <div className="formbold-main-wrapper">
          {/* Author: FormBold Team */}
          {/* Learn More: https://formbold.com */}
          <div className="formbold-form-wrapper">
            <form action="#" method="POST">
              <div className="formbold-mb-5">
                <label
                  style={{ color: "#fb8615" }}
                  htmlFor="class formbold-form-label"
                >
                  {t("addFlatFromType")}
                </label>
                <select
                  style={{ marginTop: "15px" }}
                  className="formbold-form-input"
                >
                  <option selected>{t("addFlatFromTypeItemSell")}</option>
                  <option>{t("addFlatFromTypeItemRent")}</option>
                </select>
              </div>

              <div className="formbold-mb-5">
                <label htmlFor="location" className="formbold-form-label">
                  {t("addFlatFromPreferedLocation")}
                </label>
                <input
                  type="text"
                  name="location"
                  id="location"
                  placeholder={t("addFlatFromPreferedLocationPlaceHolder")}
                  className="formbold-form-input"
                />
              </div>
              <div className="formbold-mb-5">
                <label htmlFor="size" className="formbold-form-label">
                  {t("addFlatFromPreferedSize")}
                </label>
                <input
                  type="text"
                  name="size"
                  id="size"
                  placeholder={t("addFlatFromPreferedSize")}
                  className="formbold-form-input"
                />
              </div>
              <div className="formbold-mb-5">
                <label
                  style={{ color: "#fb8615" }}
                  htmlFor="class formbold-form-label"
                >
                  {t("addFlatFromBedroom")}
                </label>
                <select
                  style={{ marginTop: "15px" }}
                  className="formbold-form-input"
                >
                  <option>1</option>
                  <option>2</option>
                  <option selected>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
              </div>

              <div className="formbold-mb-5">
                <label className="formbold-form-label">
                  {" "}
                  {t("addFlatFromBathroom")}
                </label>
                <select className="formbold-form-input">
                  <option>1</option>
                  <option selected>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
              </div>
              <div className="formbold-mb-5">
                <label className="formbold-form-label formbold-form-label-2">
                  {t("addFlatFromContactInformation")}
                </label>
                <label htmlFor="name" className="formbold-form-label">
                  {" "}
                  {t("addFlatFromName")}
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder={t("addFlatFromNamePlaceHolder")}
                  className="formbold-form-input"
                />
              </div>
              <div className="formbold-mb-5">
                <label htmlFor="phone" className="formbold-form-label">
                  {t("addFlatFromPhone")}
                </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder={t("addFlatFromPhonePlaceHolder")}
                  className="formbold-form-input"
                />
              </div>
              <div className="formbold-mb-5">
                <label htmlFor="email" className="formbold-form-label">
                  {t("addFlatFromEmail")}
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder={t("addFlatFromEmailplaceHolder")}
                  className="formbold-form-input"
                />
              </div>
              <div className="formbold-mb-5">
                <label htmlFor="address" className="formbold-form-label">
                  {" "}
                  {t("addFlatFromAdress")}
                </label>
                <input
                  type="text"
                  name="address"
                  id="address"
                  placeholder={t("addFlatFromAdressplaceHolder")}
                  className="formbold-form-input"
                />
              </div>
              <div>
                <button className="formbold-btn">
                  {t("addPropertyFormSubmitBtn")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddPropertyFlat;
