import React from "react";
import "../../css/CardBox.css";
import "../../css/BlogCardBox.css"
import { Link } from "react-router-dom";

const BlogCardBox = () => {
  return (
    <>
      <section className="CardItemTotal">
        <Link to="./blogdetails" className="CardItemTotalInner">
          <img
            src="https://images.unsplash.com/photo-1564034503-e7c9edcb420c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
            alt=""
          />
          <div className="CardItemControlar">
            <div className="CardItemContolarInner ">
              <p className="BlogCardBoxText"
                style={{
                  color: "black",
                  textRendering: "optimizeLegibility",
                  wordBreak: "break-all",
                  marginTop: "15px",
                 
                }}
              >
                {/* here maximum word limit : 19 
              
              and maximum charecters limit: 137
              with space
              */}
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde
                molestias corporis adipisci quam nostrum iure vero asperiores,
                qui alias!
              </p>
            </div>
          </div>
        </Link>
      </section>
    </>
  );
};

export default BlogCardBox;
