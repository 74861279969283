import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import CardBox from "../../component/CardBox/CardBox";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const ProductCarousel = () => {
  const {t}=useTranslation();
  return (
    <section className="cardCarousel">
      <Swiper
        spaceBetween={10}
        slidesPerView={1}
        freeMode={true}
        breakpoints={{
          // when window width is >= 640px
          0: {
            slidesPerView: 1,
          },
          227: {
            slidesPerView: 1.2,
          },
          281: {
            slidesPerView: 1.4,
          },
          300: {
            slidesPerView: 1.5,
          },
          343: {
            slidesPerView: 1.7,
          },
          387: {
            slidesPerView: 1.9,
          },
          400: {
            slidesPerView: 2,
          },
          438: {
            slidesPerView: 2.2,
          },
          451: {
            slidesPerView: 1.3,
          },
          455: {
            slidesPerView: 1.4,
          },
          500: {
            slidesPerView: 1.5,
          },
          535: {
            slidesPerView: 1.6,
          },
          600: {
            slidesPerView: 1.8,
          },
          631: {
            slidesPerView: 1.5,
          },
          664: {
            slidesPerView: 1.7,
          },
          700: {
            slidesPerView: 1.8,
          },
          800: {
            slidesPerView: 2,
          },
          900: {
            slidesPerView: 2.2,
          },
          948: {
            slidesPerView: 2.4,
          },
          1000: {
            slidesPerView: 2.5,
          },
          1100: {
            slidesPerView: 2.8,
          },
          1190: {
            slidesPerView: 3,
          },
          1250: {
            slidesPerView: 3.1,
          },
          1350: {
            slidesPerView: 3.3,
          },
          1552: {
            slidesPerView: 3.3,
          },
          1556: {
            slidesPerView: 3.4,
          },
          1557: {
            slidesPerView: 3.4,
          },
          1576: {
            slidesPerView: 3.5,
          },
          1577: {
            slidesPerView: 3.8,
          },
          1642: {
            slidesPerView: 4,
          },
          1790: {
            slidesPerView: 4.3,
          },
          1920: {
            slidesPerView: 4.5,
          },
        }}
        modules={[FreeMode, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <CardBox />
        </SwiperSlide>
        <SwiperSlide>
          <CardBox />
        </SwiperSlide>
        <SwiperSlide>
          <CardBox />
        </SwiperSlide>
        <SwiperSlide>
          <CardBox />
        </SwiperSlide>
        <SwiperSlide>
          <CardBox />
        </SwiperSlide>
        <SwiperSlide>
          <CardBox />
        </SwiperSlide>
        <SwiperSlide>
          <CardBox />
        </SwiperSlide>
      </Swiper>
      <Swiper></Swiper>
      <Link to="/search">
        <h6 style={{ float: "right", paddingRight: "15px", color: "black" }}>
         {t( "ProductMoreView")}<i className="fa-solid fa-angles-right"></i>
        </h6>
      </Link>
    </section>
  );
};

export default ProductCarousel;
