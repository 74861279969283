import { useTranslation, initReactI18next } from "react-i18next";
import i18next from "i18next";
import tEN from "./EN/translation.json";
import tBN from "./BN/translation.json";
i18next.use(initReactI18next).init({
  resources: {
    EN: {
      translation:tEN
    },
    BN: {
      translation:tBN
    },
  },
  lng: localStorage.getItem("LangName"),
  fallbackLang: "EN",
  interpolation: {
    escapeValue: false,
  },
});

const LangEngine = () => {
  const { t } = useTranslation();
  return (
    <>
      <h2>{t("welcome")}</h2>
      <h2>{localStorage.getItem("LangName")}</h2>
    </>
  );
};

export default LangEngine;
