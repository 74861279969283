import React from "react";
import AdsImg from "../../img/poroshmartAds.png";
import AdsImg2 from "../../img/adsImg2.png";
import AdsImg3 from "../../img/adsImg3.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
const HomeAdsContainers = () => {
  return (
    <section className="AdsContainer">
      <Swiper
        pagination={false}
        navigation={false}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: true,
        }}
      >
        <SwiperSlide>
          <section className="AdsCardTotalOuter">
            <div className="AdsCardTotal">
              <img src={AdsImg} alt="" />
            </div>
          </section>
        </SwiperSlide>
        <SwiperSlide>
          <section className="AdsCardTotalOuter">
            <div className="AdsCardTotal">
              <img src={AdsImg2} alt="" />
            </div>
          </section>
        </SwiperSlide>
        <SwiperSlide>
          <section className="AdsCardTotalOuter">
            <div className="AdsCardTotal">
              <img src={AdsImg3} alt="" />
            </div>
          </section>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default HomeAdsContainers;
