import React from "react";
import { useTranslation } from "react-i18next";
import "../css/HomeVideo.css";
import HomeTrackLogo from "../img/HomeTruck.png";
const HomeTrack = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="HomeVideoTotal">
        <div className="HomeVideoInner">
          <div className="HomeVideoInnerLeft">
            <div className="HomeVideoInnerLeftInner">
              <h2>
                Porosh<strong>Mart</strong>
              </h2>
              <h4>{t("HomeTrackSpeech")}</h4>
            </div>
          </div>
          <div className="HomeVideoInnerRight">
            <div className="HomeTrack">
              <div className="HomeTrackImage">
                <img src={HomeTrackLogo} alt="" />
              </div>
              <div className="HomeTrackText">
                {/* <p>বাড়ি বদল করেত কল করুন</p> */}
                <p>{t("HomeTrackSlugan")}</p>
              </div>
              <div className="HomeTrackButton">
                <button>
                  <i className="fa-solid fa-phone-volume" />
                </button>
                <button className="cardInnerItemMailBtn">
                  <i className="fa-solid fa-envelope" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeTrack;
