import React from "react";
import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
const PhoneNumberPopUpBox = () => {
  const [DilogOpen, SetOpenDilog] = useState(false);
  return (
    <>
      <button onClick={() => SetOpenDilog(true)}>
        <i className="fa-solid fa-phone-volume"></i>
      </button>
      <Dialog open={DilogOpen} onClose={() => SetOpenDilog(false)}>
        <DialogTitle>Contact Us</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <a
              href="tel:+88-01766563617"
              aria-label="Listing phone number"
              class="_643d3fb4"
            >
              <span dir="ltr">+88-01766563617</span>
            </a>
            <br />
            <a aria-label="Chat on WhatsApp" href="https://wa.me/+8801744411914">
              {" "}
              <img alt="Chat on WhatsApp" src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/479px-WhatsApp.svg.png"  style={{width:"50px"}}/>
            </a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => SetOpenDilog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PhoneNumberPopUpBox;
