import React from "react";
import "../css/searchCss/style.css";
import SearchCardBoxItem from "./SearchCardBoxItem";
const SearchCardBox = () => {
  return (
    <>
      <div className="Total">
        <div className="InnerTotal">
          <SearchCardBoxItem/>
        </div>
      </div>
    </>
  );
};

export default SearchCardBox;
