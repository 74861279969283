import React from "react";
import { useTranslation } from "react-i18next";
import "../../css/AddPropertyPages/AddPropertyForm.css";
const AddPropertyLand = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="appoinmentPage">
        <div className="appoinmentPageHeader">
          <h2>
            <i
              style={{ color: "#fb8615" }}
              className="fa-solid fa-mountain-sun"
            />{" "}
            {t("addPropertyLandTitle")}
          </h2>
        </div>
        <div className="formbold-main-wrapper">
          {/* Author: FormBold Team */}
          {/* Learn More: https://formbold.com */}
          <div className="formbold-form-wrapper">
            <form action="#" method="POST">
              <div className="formbold-mb-5">
                <label htmlFor="address" className="formbold-form-label">
                  {t("addFlatFromAdress")}
                </label>
                <input
                  type="text"
                  name="address"
                  id="address"
                  placeholder={t("addPropertyLandAddressPlaceholder")}
                  className="formbold-form-input"
                />
              </div>
              <div className="formbold-mb-5">
                <label htmlFor="size" className="formbold-form-label">
                  {t("addPropertyLandSize")}
                </label>
                <input
                  type="text"
                  name="size"
                  id="size"
                  placeholder={t("addPropertyLandSizePlaceholder")}
                  className="formbold-form-input"
                />
              </div>
              <div className="formbold-mb-5">
                <label
                  htmlFor="class formbold-form-label"
                  style={{ color: "#fb8615" }}
                >
                  {t("addPropertyLandUnit")}
                </label>
                <select
                  style={{ marginTop: "15px" }}
                  className="formbold-form-input"
                >
                  <option selected>{t("addPropertyLandUnitItemKatha")}</option>
                  <option> {t("addPropertyLandUnitItemBigha")}</option>
                  <option> {t("addPropertyLandUnitItemChotak")}</option>
                  <option> {t("addPropertyLandUnitItemBargafoot")}</option>
                </select>
              </div>
              <div className="formbold-mb-5">
                <label className="formbold-form-label formbold-form-label-2">
                  {t("addPropertyLandOwnerInformation")}
                </label>
                <label htmlFor="name" className="formbold-form-label">
                  {t("addPropertyLandOwnerName")}
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Enter your name"
                  className="formbold-form-input"
                />
              </div>
              <div className="formbold-mb-5">
                <label htmlFor="phone" className="formbold-form-label">
                  {t("addFlatFromPhone")}
                </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder={t("addFlatFromPhonePlaceHolder")}
                  className="formbold-form-input"
                />
              </div>
              <div className="formbold-mb-5">
                <label htmlFor="email" className="formbold-form-label">
                  {t("addFlatFromEmail")}
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder={t("addFlatFromEmailplaceHolder")}
                  className="formbold-form-input"
                />
              </div>
              <div className="formbold-mb-5">
                <label htmlFor="address" className="formbold-form-label">
                  {" "}
                  {t("addFlatFromAdress")}
                </label>
                <input
                  type="text"
                  name="address"
                  id="address"
                  placeholder={t("addFlatFromAdressplaceHolder")}
                  className="formbold-form-input"
                />
              </div>
              <div>
                <button className="formbold-btn">
                  {t("addPropertyFormSubmitBtn")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddPropertyLand;
