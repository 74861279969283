import React from "react";
import "../css/SinglePageFooterNavbar.css";
const SinglePageFooterNavbar = () => {
  return (
    <>
      <section className="SinglePageFooterNavbar">
        <div className="SinglePageFooterNavbarInner">
          <button>
            <i class="fa-solid fa-envelope"></i>
          </button>
          <button>
            <i class="fa-solid fa-phone-volume"></i>
          </button>
          <button>
          <i class="fa-brands fa-whatsapp"></i>
          </button>
       
        </div>
      </section>
    </>
  );
};

export default SinglePageFooterNavbar;
