import React from "react";
import "../../css/AddPropertyPages/AddPropertyBtnPage.css";
import Lottie from "lottie-react";
import LottieHouseAnimation from "../../lottie/SAkHw3V2TA.json";
import LottieMapAnimation from "../../lottie/s6SnchHXp3.json";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const AddPropertyBtn = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="AddPropertyBtnPageTotal">
        <div className="AddPropertyBtnPageFlatDesign">
          <Lottie animationData={LottieHouseAnimation} loop={true} />
        </div>
        <Link
          className="AddPropertyBtnPageFlatDesignBtn"
          to={"/addproperty/flat"}
        >
          <i class="fa-solid fa-house-flag"></i> 
          {t("addPropertPageFlatHotel")}
        </Link>
        <Link
          style={{ marginBottom: "0px" }}
          className="AddPropertyBtnPageFlatDesignBtn"
          to={"/addproperty/land"}
        >
          <i class="fa-solid fa-signs-post"></i>  {t("addPropertPageLand")}
        </Link>
        <div
          style={{ marginTop: "50px" }}
          className="AddPropertyBtnPageFlatDesign"
        >
          <Lottie animationData={LottieMapAnimation} loop={true} />
        </div>
      </section>
    </>
  );
};

export default AddPropertyBtn;
